import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../provider/AuthProvider";
import axios from "axios";
import { serverbaseURL } from "../constant/index";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { googleSignIn, setLoading, setPostLoginCallback } =
    useContext(AuthContext);


  const handleGoogleSignIn = async () => {
    try {
      const googleData = await googleSignIn();
      console.log('googleData', googleData);
      const formData = JSON.parse(localStorage.getItem("formData"));
      if (formData) {
        const executeSeriesCreation = async () => {
          const user = JSON.parse(localStorage.getItem("user"));
          console.log("form Data", formData);
          const data = {
            userEmail: user?.email,
            ...formData,
          };
          try {
            const response = await axios.post(`${serverbaseURL}series`, data);
            const resData = await response.data;
            console.log("resData", resData);
            alert(`${resData.message}`);
            localStorage.removeItem("formData"); // Clear the form data after submission
          } catch (error) {
            console.error("error in google login", error);
          }

        };
        setPostLoginCallback(() => executeSeriesCreation); // Set the callback for post-login
      }
      navigate("/dashboard");
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <div>
      {/* <Navbar/> */}
      <div className="bg-white flex justify-center items-center">
        <div className="h-[300px] bg-primary rounded-lg p-10 mt-20">
          <h1 className="text-white text-3xl text-center font-semibold">
            Login
          </h1>
          <div className="flex justify-center items-center pt-10">
            <button
              className="bg-white text-black rounded-lg px-5 py-2 font-semibold"
              onClick={handleGoogleSignIn}
            >
              Sign in with Google
            </button>
          </div>
          <div className="flex justify-center items-center gap-1 pt-4 text-white">
            <p>Not registered yet.</p>
            <Link to="/register" className="underline">
              Register
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
