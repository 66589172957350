import React, { useState } from "react";
import axios from "axios";
import { serverbaseURL } from "../constant/index";

const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    category: "",
    description: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${serverbaseURL}support-tickets`, formData);
      alert("Support request submitted successfully!");
      setFormData({
        name: "",
        email: "",
        subject: "",
        category: "",
        description: "",
      });
    } catch (error) {
      console.error("Error submitting support request:", error);
      alert("Failed to submit support request. Please try again.");
    }
  };

  return (
    <div className="bg-white flex justify-center items-center min-h-screen">
      <div className="w-full max-w-md bg-primary rounded-lg p-8">
        <h1 className="text-white text-3xl text-center font-semibold mb-6">
          Support Request
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your Name"
            className="w-full px-3 py-2 rounded"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your Email"
            className="w-full px-3 py-2 rounded"
            required
          />
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            placeholder="Subject"
            className="w-full px-3 py-2 rounded"
            required
          />
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="w-full px-3 py-2 rounded"
            required
          >
            <option value="">Select Category</option>
            <option value="technical">Technical Issue</option>
            <option value="billing">Billing</option>
            <option value="general">General Inquiry</option>
          </select>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Describe your issue"
            className="w-full px-3 py-2 rounded"
            rows="4"
            required
          ></textarea>
          <button
            type="submit"
            className="w-full bg-white text-primary font-semibold py-2 rounded hover:bg-gray-100"
          >
            Submit Request
          </button>
        </form>
      </div>
    </div>
  );
};

export default Support;
