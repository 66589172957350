import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
} from "lucide-react";
import { AuthContext } from "../provider/AuthProvider";
import axios from "axios";
import Modal from 'react-modal'; // Install this if using react-modal

import {
  serverbaseURL,
} from "../constant/index.jsx";

const ViewGenerations = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  
  const [videoItem, setVideoItem] = useState(null);
  const [generations, setGenerations] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (location.state && location.state.item) {
      setVideoItem(location.state.item);

      const fetchSeriesData = async () => {
        try {
          const response = await axios.get(
            `${serverbaseURL}get_Generations?email=${user?.email}&seriesId=${location.state.item._id}`
          );
          let vidGens = response?.data;
          setGenerations(vidGens);
        } catch (error) {
          console.error("Error fetching generations:", error);
          setError("Failed to fetch generations. Please try again later.");
        }
      };

      fetchSeriesData();
    } else {
      navigate("/");
    }
  }, [location, navigate, user]);

  const openModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideoUrl(null);
  };

  const renderAdvancedForm = () => {
    if (error) {
      return (
        <div className="p-8 w-full rounded-lg bg-red-100 text-red-700">
          <p>{error}</p>
        </div>
      );
    }

    return generations?.length > 0 ? (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 px-4 border-b">Generated date</th>
              <th className="py-2 px-4 border-b">Channel Name</th>
              <th className="py-2 px-4 border-b">Video Link</th>
            </tr>
          </thead>
          <tbody>
            {generations?.map((item) => (
              <tr key={item._id} className="hover:bg-gray-50">
                <td className="py-2 px-4 border-b">{item.createdOn}</td>
                <td className="py-2 px-4 border-b">{item.channelName}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => openModal(item.videoUrl)}
                    className="text-blue-500 hover:underline"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="p-8 w-full rounded-lg bg-slate-600">
        <p className="text-white pb-8">
          You haven't started a Faceless Video channel yet.
        </p>

        <Link to="/dashboard/create">
          <button className="bg-gradient-to-r from-primary to-blue-700 text-white py-3 px-6 text-lg rounded-lg font-semibold mb-5">
            CREATE YOUR CHANNEL
          </button>
        </Link>
      </div>
    );
  };

  if (!videoItem) {
    return <div>Loading...</div>;
  }

  return (
    <div className="edit-video-container p-6 bg-gray-100 rounded-lg shadow-md">
      <div className="mb-6">
        <Link
          to="/dashboard"
          className="flex items-center text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out"
        >
          <ArrowLeft className="mr-2" size={20} />
          <span className="text-lg font-semibold">Back to Dashboard</span>
        </Link>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-sm">
        {renderAdvancedForm()}
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <button onClick={closeModal} className="close-button">Close</button>
          {selectedVideoUrl && (
            <iframe
              width="100%"
              height="400px"
              src={selectedVideoUrl}
              title="Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ViewGenerations;