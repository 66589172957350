import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { serverbaseURL } from "../constant/index";
import { AuthContext } from "../provider/AuthProvider";
import { useNavigate } from "react-router-dom";

const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { user, googleSignIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${serverbaseURL}templates`);
      setTemplates(response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setShowPreview(true);
  };

  const handleSelect = (template) => {
    if (!user) {
      setShowLoginPopup(true);
    } else {
      if (template.type === "Custom") {
        setSelectedTemplate(template);
        setShowCustomForm(true);
      } else {
        navigate("/create-ui2");
      }
    }
  };

  const handleCustomFormSubmit = async (e) => {
    e.preventDefault();
    // Implement the logic to submit the custom template request
    setShowCustomForm(false);
  };

  const handleLogin = async () => {
    try {
      await googleSignIn();
      setShowLoginPopup(false);
      navigate("/create-ui2");
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <div className="bg-white min-h-screen p-8">
      <h1 className="text-3xl font-semibold mb-6">Templates</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {templates.map((template) => (
          <div key={template.id} className="bg-primary rounded-lg p-6 text-white">
            <img src={template.image} alt={template.name} className="w-full h-48 object-cover rounded mb-4" />
            <h2 className="text-xl font-semibold mb-2">{template.name}</h2>
            <p className="mb-4">{template.description}</p>
            <p className="mb-4">Type: {template.type}</p>
            <button
              onClick={() => handlePreview(template)}
              className="bg-white text-primary px-4 py-2 rounded mr-2 hover:bg-gray-100"
            >
              Preview
            </button>
            <button
              onClick={() => handleSelect(template)}
              className="bg-white text-primary px-4 py-2 rounded hover:bg-gray-100"
            >
              Select
            </button>
          </div>
        ))}
      </div>

      {showPreview && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">{selectedTemplate.name} Preview</h2>
            <video controls className="w-full max-w-2xl">
              <source src={selectedTemplate.previewVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button
              onClick={() => setShowPreview(false)}
              className="mt-4 bg-primary text-white px-4 py-2 rounded hover:bg-opacity-90"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showCustomForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Request Custom Template API</h2>
            <form onSubmit={handleCustomFormSubmit} className="space-y-4">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full px-3 py-2 rounded border"
                required
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full px-3 py-2 rounded border"
                required
              />
              <textarea
                placeholder="Describe your custom template requirements"
                className="w-full px-3 py-2 rounded border"
                rows="4"
                required
              ></textarea>
              <button
                type="submit"
                className="w-full bg-primary text-white font-semibold py-2 rounded hover:bg-opacity-90"
              >
                Submit Request
              </button>
            </form>
            <button
              onClick={() => setShowCustomForm(false)}
              className="mt-4 bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {showLoginPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Login Required</h2>
            <p className="mb-4">Please log in to continue.</p>
            <button
              onClick={handleLogin}
              className="w-full bg-primary text-white font-semibold py-2 rounded hover:bg-opacity-90"
            >
              Log In with Google
            </button>
            <button
              onClick={() => setShowLoginPopup(false)}
              className="mt-4 w-full bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Templates;
